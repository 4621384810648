<template>
    <a-modal
            :visible="value"
            title="开播记录"
            width="900px"
            @cancel="handleCancel"
            centered
    >
        <template #footer></template>
        <div class="wrapper">
            <div class="header">
                <span>主播：<a target="_blank" :href="'https://space.bilibili.com/'+uid[1]">{{ `${uid[0]}(${uid[1]})` }}</a></span>
                <span>近七日开播天数：{{ live_days }}天</span>
            </div>
            <div class="table-box">
                <a-table
                        :columns="columns"
                        :data-source="dataSource"
                        :pagination="pageOptions"
                        @change="handleTableChange"
                >
                    <template v-slot:cover="cover">
                        <div v-prevue-image>
                            <img
                                class="img"
                                :src="cover"
                                alt="封面"
                            />
                        </div>
                    </template>
                </a-table>
            </div>
        </div>
    </a-modal>
</template>

<script>

import {MixinList} from "@/common/v2/mixin";

const mixinList = new MixinList(
    function (data) {
        let {live_days, items} = data;
        this.live_days = live_days;
        return items.map((item) => ({
            cover: item.user_cover,
            title: item.title,
            range: item.start_time + ' ~ ' + item.end_time,
        }));
    },
    function (data) {
        return data.total_count;
    }
)

const columns = [
    {
        title: '封面',
        dataIndex: 'cover',
        key: 'cover',
        align: 'center',
        scopedSlots: {customRender: 'cover'},
        width: 0,
    },
    {
        title: '标题',
        dataIndex: 'title',
        key: 'title',
        align: 'center',
    },
    {
        title: '开播时间',
        dataIndex: 'range',
        key: 'range',
        align: 'center',
    },
]
export default {
    name: "LiveRecordDialog",
    props: ['value', 'uid'],
    mixins: [mixinList],
    data() {
        return {
            dataUrl: '/v2/autoSendStar/getPersonalRec',
            columns,
            live_days: 0,
        }
    },
    methods: {
        handleCancel() {
            this.$emit('input', false);
        },
    },
    beforeMount() {
        this.queryParam.uid = this.uid[1];
    },
}
</script>

<style lang="less" scoped>
.wrapper {
  .header {
    display: flex;

    > * {
      margin-right: 30px;
    }
  }

  .table-box {
    height: 50vh;
    overflow-y: scroll;

    .img {
      height: 50px;
    }
  }
}
</style>
