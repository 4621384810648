<template>
  <a-modal
      :visible="value"
      title="设置流水范围发放星光"
      width="900px"
      @ok="handleOk"
      @cancel="handleCancel"
      :closable="false"
      :mask-closable="false"
      centered
  >
    <div class="box">
      <div class="content">
        <div class="item" v-for="(v,k) in dataSource" :key="k">
          <span>近七日流水达到</span>
          <a-input-number
              v-model="v.start"
              :min="0"
              placeholder="请输入流水(人民币)"
              style="min-width: 200px"
          />
          <span style="font-weight: bold">~</span>
          <a-input-number
              v-model="v.end"
              :min="0"
              placeholder="请输入流水(人民币)"
              style="min-width: 200px"
          />
          <span>发放</span>
          <a-input-number
              v-model="v.star"
              :min="0"
              placeholder="请输入星光数量"
              style="min-width: 160px"
          />
          <span>星光</span>
          <a-icon class="del" type="delete" @click="dataSource.splice(k,1)" :style="{visibility:k===0?'hidden':'visible'}"/>
        </div>
        <a-button class="add" @click="add">
          <a-icon type="plus"/>
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
export default {
  name: "ProvideStarDialog",
  props: ['value','dataSource'],
  data() {
    return {
    }
  },
  methods: {
    handleOk() {
      this.$api.post('/v2/autoSendStar/autoSendStarRules', {
        conf: this.dataSource,
      }).then(resp => {
        let {code, message} = resp;
        if (code === 200) {
          this.$message.success(message);
          this.$emit('input', false);
        } else {
          this.$message.error(message);
        }
      }).catch(e => {
        throw e;
      });
    },
    handleCancel() {
      this.$emit('input', false);
    },
    add() {
      this.dataSource.push({
        start: '',
        end: '',
        star: '',
      });
    },
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 50vh;
  white-space: nowrap;

  display: flex;
  justify-content: center;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    > * {
      margin-bottom: 10px;
    }

    .item {
      display: flex;
      align-items: center;

      span {
        margin: 0 6px;
      }

      .del {
        color: red;
        font-size: 3ex;

        cursor: pointer;
      }
    }

    .add {
      width: 100%;
    }
  }

}
</style>
